import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { State } from './practice.reducer';
import { Observable } from 'rxjs';
import {
  selectError,
  selectLoaded,
  selectLoading,
  selectPractice,
  selectPracticeGuid
} from './practice.selectors';
import { loadPractice } from './practice.actions';
import { filter, map } from 'rxjs/operators';
import { PaymentsOperator, Practice } from '../../data-access/practice';
import { ErrorState } from '../../common/call-state';

@Injectable({
  providedIn: 'root'
})
export class PracticeFacade {
  practiceGuid$: Observable<string> = this.store.pipe(
    select(selectPracticeGuid),
    filter((practiceGuid) => !!practiceGuid)
  );
  practice$: Observable<Practice> = this.store.pipe(
    select(selectPractice),
    filter((practice) => !!practice)
  );
  loading$: Observable<boolean> = this.store.pipe(select(selectLoading));
  loaded$: Observable<boolean> = this.store.pipe(select(selectLoaded));
  error$: Observable<ErrorState> = this.store.pipe(select(selectError));

  clearentPaymentsEnabled$: Observable<boolean> = this.practice$.pipe(
    filter((practice) => !!practice),
    map(
      (practice) =>
        practice.payments_operator === PaymentsOperator.CLEARENT &&
        practice.payments_patient_portal_enabled
    )
  );

  balancesEnabled$: Observable<boolean> = this.practice$.pipe(
    filter((practice) => !!practice),
    map((practice) => practice.balance_patient_portal_enabled)
  );

  isSurchargeEnabled$: Observable<boolean> = this.practice$.pipe(
    filter((practice) => !!practice),
    map((practice) => !!practice.surcharge_enabled)
  );

  constructor(private readonly store: Store<State>) {}

  loadPractice(practiceGuid: string): void {
    this.store.dispatch(loadPractice({ practiceGuid }));
  }
}
